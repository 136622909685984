export const pul = {
  valyuta: "Валюта",
  som: "Сўм",
};

export const xodimTurlari = {
  agent: "Агент",
  dostavshik: "Юк етказиб берувчи",
  boshliq: "Бошлиқ",
  mijoz: "Мижоз",
};
