import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { BaseUrl } from "../../BaseUrl";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  MODALTABLE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import DocumentClass from "../../Functions/DocumentClass";
import moment from "moment";
import NumberFormat from "../../Functions/NumberFormat";
import ChangeMaxsulot from "./Components/ChangeMaxsulot";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import ShowAllBtn from "./Components/ShowAllBtn";
import { pul } from "../../Functions/Constanta";
import SanaFormat from "../../Functions/SanaFormat";
import MiqdorOstatka from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorOstatka";
import KirimInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/KirimInput";
const MaxsulotQoldigi = {
  title: "Бош маҳсулот қолдиғи",
  path: "/maxsulot-qoldigi",
  type: SIMPLE,
  icon: <RiStackFill></RiStackFill>,
  allData: [{ value: "maxsulot_id", prop: "tovarlar", get: "nomi" }],
  modalJami: "maxsulot-kirimi",
  innerData: "tovarlar",
  modalWidth: "1336px",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "nomi",
      render: (text) => SanaFormat(text),
      width: "120px",
    },
    {
      title: <div className="table_head">Кирим сумма</div>,
      children: [
        {
          title: <div className="table_head">Сўм</div>,
          dataIndex: "document",
          key: "nomi",
          align: "right",
          width: "130px",
          render: (text) =>
            NumberFormat(DocumentClass(text, "maxsulot-qoldigi").kirim_summa),
        },
        {
          title: <div className="table_head">Вал</div>,
          dataIndex: "document",
          key: "nomi",
          align: "right",
          width: "110px",
          render: (text) =>
            NumberFormat(
              DocumentClass(text, "maxsulot-qoldigi").kirim_val_summa,
              1
            ) + " $",
        },
      ],
    },
    {
      title: <div className="table_head">Жами миқдор</div>,
      dataIndex: "document",
      key: "nomi",
      align: "center",
      width: "80px",
      render: (text) => DocumentClass(text, "maxsulot-qoldigi").miqdor_soni,
    },
    {
      title: <div className="table_head">Сотиш сумма</div>,
      children: [
        {
          title: <div className="table_head">Сўм</div>,
          dataIndex: "document",
          key: "nomi",
          align: "right",
          width: "130px",
          render: (text) =>
            NumberFormat(DocumentClass(text, "maxsulot-qoldigi").sotish_summa),
        },
        {
          title: <div className="table_head">Вал</div>,
          dataIndex: "document",
          key: "nomi",
          align: "right",
          width: "110px",
          render: (text) =>
            NumberFormat(
              DocumentClass(text, "maxsulot-qoldigi").sotish_val_summa,
              1
            ) + " $",
        },
      ],
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "nomi",
      width: "178px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "2fr 4fr",
          inputs: [
            {
              label: "Сана",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Изох",
              name: "izoh",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Маҳсулотлар",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "40px",
                align: "center",
              },
              {
                title: <div className="table_head">Маҳсулот</div>,
                dataIndex: "nomi",
                key: "nomi",
                width: "250px",
              },
              {
                title: <div className="table_head">Блок сони</div>,
                dataIndex: "blok_soni",
                key: "blok_soni",
                width: "80px",
                align: "center",
              },
              {
                title: <div className="table_head">Валюта тури</div>,
                dataIndex: "valyuta_turi",
                key: "blok_soni",
                width: "80px",
                render: (text) => (text == 1 ? pul.valyuta : pul.som),
              },
              {
                title: <div className="table_head">Кирим нархи</div>,
                dataIndex: "kirim_narxi",
                align: "right",
                width: "101px",
                render: (text, record) =>
                  record.valyuta_turi == 1
                    ? NumberFormat(text, 1)
                    : NumberFormat(text),
              },
              {
                title: <div className="table_head">Сотиш нархи</div>,
                dataIndex: "sotish_narxi",
                width: "101px",
                align: "right",
                render: (text, record) =>
                  record.valyuta_turi == 1
                    ? NumberFormat(text, 1)
                    : NumberFormat(text),
              },
              {
                title: <div className="table_head">Расми</div>,
                dataIndex: "img",
                width: "80px",
                key: "img",
                render: (text) => {
                  if (text) {
                    return (
                      <picture>
                        <source
                          media="max-width: 200px"
                          srcSet={BaseUrl + text.slice(1)}
                        />
                        <img
                          src={BaseUrl + text.slice(1)}
                          alt="asda"
                          height={30}
                        />
                      </picture>
                    );
                  } else return "";
                },
              },
            ],
          },

          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "30px",
              align: "center",
            },
            {
              title: <div className="table_head">Маҳсулот</div>,
              dataIndex: "element_id",
              key: "nomi",
              width: "250px",
              render: (text) => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"element_id"}
                    value={text}
                    placeholder="Махсулот"
                    get="nomi"
                    cursor="1"
                    innerDataType={"tovarlar"}
                    document={true}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
            {
              title: <div className="table_head">Блок сони</div>,
              dataIndex: "blok_soni",
              key: "nomi",
              align: "center",
              width: "50px",
            },
            {
              title: <div className="table_head">Нарх тури</div>,
              dataIndex: "valyuta_turi",
              key: "valyuta_turi",
              width: "50px",
              render: (text) => (text === 1 ? pul.valyuta : pul.som),
            },
            {
              title: <div className="table_head">Миқдор</div>,
              children: [
                {
                  title: <div className="table_head">блок</div>,
                  dataIndex: "miqdor_blok",
                  key: "nomi",
                  width: "90px",
                  render: (text, record) => (
                    <MiqdorOstatka
                      name="miqdor_blok"
                      type="blok"
                      blok_soni={record.blok_soni}
                      value={text}
                    ></MiqdorOstatka>
                  ),
                },
                {
                  title: <div className="table_head">сони</div>,
                  dataIndex: "miqdor_soni",
                  key: "nomi",
                  width: "90px",
                  render: (text, record) => (
                    <MiqdorOstatka
                      name="miqdor_soni"
                      type="name"
                      blok_soni={record.blok_soni}
                      value={text}
                    ></MiqdorOstatka>
                  ),
                },
              ],
            },
            {
              title: <div className="table_head">Кирим нархи</div>,
              children: [
                {
                  title: <div className="table_head">сўм</div>,
                  dataIndex: "kirim_som",
                  key: "nomi",
                  width: "110px",
                  render: (text, record) => (
                    <KirimInput
                      name="kirim_som"
                      record={record}
                      value={text}
                    ></KirimInput>
                  ),
                },
                {
                  title: <div className="table_head">вал</div>,
                  dataIndex: "kirim_val",
                  key: "nomi",
                  width: "90px",
                  render: (text, record) => (
                    <KirimInput
                      name="kirim_val"
                      record={record}
                      value={text}
                    ></KirimInput>
                  ),
                },
              ],
            },
            {
              title: <div className="table_head">Расми</div>,
              dataIndex: "img",
              width: "70px",
              align: "center",
              key: "img",
              render: (text) => {
                if (text) {
                  return (
                    <picture>
                      <source
                        media="max-width: 200px"
                        srcSet={BaseUrl + text.slice(1)}
                      />
                      <img
                        src={BaseUrl + text.slice(1)}
                        alt="asda"
                        height={30}
                      />
                    </picture>
                  );
                } else return "";
              },
            },
            {
              title: <div className="table_head">Сотиш нархи</div>,
              children: [
                {
                  title: <div className="table_head">сўм</div>,
                  dataIndex: "sotish_som",
                  key: "nomi",
                  width: "110px",
                  render: (text, record) => (
                    <KirimInput
                      name="sotish_som"
                      record={record}
                      value={text}
                    ></KirimInput>
                  ),
                },
                {
                  title: <div className="table_head">вал</div>,
                  dataIndex: "sotish_val",
                  key: "nomi",
                  width: "90px",
                  render: (text, record) => (
                    <KirimInput
                      name="sotish_val"
                      record={record}
                      value={text}
                    ></KirimInput>
                  ),
                },
              ],
            },
            {
              title: <div className="table_head">Кирим сумма</div>,
              children: [
                {
                  title: <div className="table_head">Сўм</div>,
                  dataIndex: "kirim_som",
                  key: "nomi",
                  width: "120px",
                  render: (text, record) =>
                    NumberFormat(text * record.miqdor_soni),
                },
                {
                  title: <div className="table_head">Вал</div>,
                  dataIndex: "kirim_val",
                  key: "nomi",
                  width: "90px",
                  render: (text, record) =>
                    NumberFormat(text * record.miqdor_soni, 1) + " $",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MaxsulotQoldigi;
