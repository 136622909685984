import axios from 'axios';
import {BaseUrl} from "./BaseUrl";
import {useEffect, useState} from "react";
import {notification} from "antd";
import {connect, useDispatch, useSelector} from "react-redux";
import {LAST_SEEN, TIME} from "./Actions/AppTypes";
import {useHistory} from "react-router-dom";
import {setCurrentPage} from "./Actions/AppActions";
import AgentBuyurtma from "./Pages/Savdo/AgentBuyurtma";


function Notification({setCurrentPage}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [duration, setDuration] = useState(0);
    const [data, setData] = useState([]);
    const {app} = useSelector(s => s);
    let arr = []

    function getData() {
        let ketmon = [
            {fio: "Sdfsdfsd", buyurtma_id: 1000},
            {fio: "sdfsdfsf", buyurtma_id: 1200},
        ];
        axios.get(BaseUrl + "/agent-buyurtma-yangi").then(res => {
            if (res.data) {
                arr = res.data;
                getYangi();
            }
        }).catch(err => console.log(err));

    }

    function getYangi() {
        if (arr) {
            dispatch({type: LAST_SEEN, payload: arr});
            let clearDiv = document.querySelectorAll('.ant-notification-notice');
            if(clearDiv){
                clearDiv.forEach(item => item.classList.add("my-d-none"))
            }
            arr.forEach(item => {
                notification.open({
                    message: item.fio, duration: app.time, onClick: () => {
                        setCurrentPage(AgentBuyurtma);
                        history.push('/agent-buyurtma');
                    }
                })
            })
        }
    }

    useEffect(() => {
        getData();
        setInterval(() => {
        dispatch({type: TIME, payload: null})
            const timer = setTimeout(() => {
                dispatch({type: TIME, payload: 0});
                getData()
            }, 1200)
            return () => clearTimeout(timer)
        }, 39000);
    }, []);

    return <div>

    </div>
}

const mapStateToProps = (state) => {
    return {
        currentPage: state.app.currentPage,
    };
};
export default connect(mapStateToProps, {
    setCurrentPage,
})(Notification);
