import { RiServerFill } from "react-icons/ri";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { HISOBOT } from "../../Component/Sidebar/PageTypes";
import PrintBtn from "./components/PrintBtn";
import Refresh from "./components/Refresh";
const MijozlarQoldigi = {
  title: "Мижозлар қолдиги", 
  path: "/hisobot/mijozlar-qoldigi",
  icon: <RiServerFill></RiServerFill>,
  type: HISOBOT,
  allData: [{ value: "mijoz_id", prop: "mijozlar", get: "fio" },
  { value: "viloyat_id", prop: "viloyatlar", get: "nomi" },
  { value: "shahar_id", prop: "shaharlar", get: "nomi" },],
  hisobotActions: [
    {
      grid: "1fr 2fr 2fr 2fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana3",
          type: DATE,
        },
        {
          label: "Вилоят",
          name: "viloyat_id",
          type: SELECT,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Шахар / Туман",
          name: "shahar_id",
          type: SELECT,
          allData: {
            dependent: "viloyat_id",
            type: "shaharlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Мижозлар",
          name: "mijoz_id",
          type: SELECT,
          allData: {
            dependent: "shahar_id",
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <PrintBtn></PrintBtn>
            </div>
          ),
        },
      ],
    },
  ],
};
export default MijozlarQoldigi;
