import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {
    changeDocumentObj,
    setDocument,
    stateDetector,
} from "../../../../Actions/AppActions";
import {Select} from "antd";

const {Option} = Select;
const MiqdorInput = ({
                         setDocument,
                         name,
                         value,
                         options,
                         placeholder,
                         allData,
                         allDataType,
                         get,
                         innerData,
                         innerDataType,
                         document,
                         changeDocumentObj,
                         rowId,
                         stateDetector,
                         currentPage,
                         cursor,
                     }) => {
    function onSearch() {
    }

    useEffect(() => {
        setTimeout(() => {
            if (cursor == 1) {
                selectRef.current.focus();
            }
        }, 20);
    }, []);
    const selectRef = useRef(null);

    return (
        <Select
            value={isNaN(value) ? "" : Number(value)}
            style={{width: 120}}
            showSearch
            ref={cursor == 1 ? selectRef : null}
            onSearch={onSearch}
            filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{overflow: "hidden"}}
            onChange={(v) => {
                let a = {
                    target: {
                        name: name,
                        value: v,
                    },
                };
                if (document) {
                    let obj1 = {};
                    const one = innerData[innerDataType].find((d) => d.Id == v)
                        ? {
                            ...innerData[innerDataType].find((d) => d.Id == v),
                            Id: rowId,
                            element_id: innerData[innerDataType].find((d) => d.Id == v).Id,
                        }
                        : {};
                    if (
                        currentPage.path == "/maxsulot-kirimi" ||
                        currentPage.path == "/maxsulot-qoldigi" ||
                        currentPage.path == "/yuk-beruvchi-vozvrat"
                    ) {
                        obj1 = {
                            kirim_som: one.valyuta_turi == 2 ? one.kirim_narxi : 0,
                            kirim_val: one.valyuta_turi == 1 ? one.kirim_narxi : 0,
                            sotish_som: one.valyuta_turi == 2 ? one.sotish_narxi : 0,
                            sotish_val: one.valyuta_turi == 1 ? one.sotish_narxi : 0,
                            zapis: false,
                        };
                    }
                    changeDocumentObj({...one, ...obj1}, rowId);
                    stateDetector();
                } else {
                    setDocument(a);
                }
            }}
        >
            {allDataType
                ? allData[allDataType]?.map((d) => (
                    <Option value={d.Id} key={d.Id}>
                        {d[get]}
                    </Option>
                ))
                : null}
            {options &&
                options.map((op) => (
                    <Option value={op.value} key={op.value}>
                        {op.text}
                    </Option>
                ))}
            {innerDataType
                ? innerData[innerDataType]?.map((d) => (
                    <Option value={d.Id} key={d.Id}>
                        {d[get]}
                    </Option>
                ))
                : null}
        </Select>
    );
};
const mapStateToProps = (state) => {
    return {
        allData: state.app.allData,
        innerData: state.app.innerData,
        rowId: state.app.rowId,
        currentPage: state.app.currentPage,
    };
};
export default connect(mapStateToProps, {
    setDocument,
    stateDetector,
    changeDocumentObj,
})(MiqdorInput);
