import React from "react";
import MainTable from "../../Tables/MainTable";
import Actions from "../../Actions/Actions";

const FotoHisobotPage = ({searchDataSource}) => {
    return (
        <MainTable costumColumns={false} data={searchDataSource}/>
    );
};

export default FotoHisobotPage;
