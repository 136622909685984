import React, { useEffect } from "react";
import {
  RiAddLine,
  RiCloseLine,
  RiFileExcel2Fill,
  RiHonourFill,
  RiPencilLine,
  RiPrinterFill,
  RiRefreshFill,
} from "react-icons/ri";
import { Button, DatePicker, message, Popconfirm } from "antd";
import { useState } from "react";
import "./Actions.css";
import {
  setModal,
  getMainTableData,
  setValues,
  setRowId,
  setHisobot,
} from "../../Actions/AppActions";
import { connect } from "react-redux";
import fetchApi from "../../Functions/fetchApi";
import Excel from "../../Functions/Excel";
import { useHistory } from "react-router-dom";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

const Actions = ({
  setModal,
  rowId,
  data,
  setValues,
  currentPage,
  getMainTableData,
  allData,
  setRowId,
  setHisobot,
  values,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", KeyboardEventss);

    return () => {
      document.removeEventListener("keydown", KeyboardEventss);
    };
  }, []);

  const KeyboardEventss = (event) => {
    switch (event.key) {
      case "Insert":
        openAdd();
        break;

      default:
        break;
    }
  };
  const openAdd = () => {
    if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
      let obj = {};
      currentPage.defaultValues.forEach((d) => {
        obj[d.prop] = d.value;
      });
      setValues(obj);
    }
    setRowId(null);
    setModal(true);
  };

  const { location } = useHistory();
  const openEdit = () => {
    if (rowId) {
      let obj = data.find((d) => d.Id === rowId);
      if (
        location.pathname == "/servis/brendlar" &&
        currentPage.defaultDocument
      ) {
        obj = {
          ...obj,
          document: allData[currentPage.defaultDocument.allData]
            .filter((d) => d[currentPage.defaultDocument.prop] == rowId)
            .map((m) => {
              return { ...m, element_id: m.Id };
            }),
        };
      }
      setValues(obj);
      setModal(true);
    } else {
      message.warning("Qatorni tanlang!");
    }
  };

  const handleDelete = async () => {
    let user_id = null;
    if (
      JSON.parse(window.localStorage.getItem("user")) &&
      JSON.parse(window.localStorage.getItem("user")).id
    ) {
      user_id = JSON.parse(window.localStorage.getItem("user")).id;
    }
    if (rowId) {
      setLoading(true);
      const res = await fetchApi(currentPage.path + "/" + rowId, "DELETE", {
        user_id: user_id,
      });
      if (res && res.status === 200) {
        setRowId(null);
        RefreshPage();
      }
      setVisible(false);
      setLoading(false);
    } else {
      message.warning("Qatorni tanlang!");
    }
  };

  const toExcel = () => {
    const mapedData = data.map((d) => {
      let a = {};
      currentPage.allData.forEach((obj) => {
        if (allData[obj.prop]?.find((k) => k.Id == d[obj.value])) {
          a[obj.prop] = allData[obj.prop]?.find((k) => k.Id == d[obj.value])[
            obj.get
          ];
          delete d[obj.value];
        }
      });
      if (d.hasOwnProperty("valyuta_turi")) {
        d.valyuta_turi = d.valyuta_turi == 1 ? "Валюта" : "Сўм";
      }
      if (d.hasOwnProperty("turi")) {
        d.turi = d.turi == 3 ? "Мижоз" : d.turi == 1 ? "Агент" : "Доставчик";
      }
      return { ...d, ...a };
    });
    Excel(mapedData);
  };
  const history = useHistory();
  const toPrint = async () => {
    if (rowId) {
      // send to server for printed

      history.push("/print");
      await fetchApi("/print", "POST", {
        Id: rowId,
      });
    }
  };
  const RefreshPage = () => {
    setLoading(true);
    let sana_1 = "";
    let sana_2 = "";
    if (!values?.sana1) {
      sana_1 = moment().format("YYYY-MM-DD");
    } else {
      sana_1 = values.sana1;
    }
    if (!values?.sana2) {
      sana_2 = moment().format("YYYY-MM-DD");
    } else {
      sana_2 = values.sana2;
    }
    if (sana_1 > sana_2) {
      message.warning("Iltimos sanalarni to'g'ri kiriting");
    } else {
      getMainTableData(location.pathname + "/" + sana_1 + "/" + sana_2);
    }
    setLoading(false);
  };
  const toNaklad = () => {
    setHisobot(null);
    history.push("/hisobot/naklad");
  };
  const toKassaXisob = () => {
    window.location.href = "/hisobot/kassa-hisobi";
  };
  const toRestrTolov = () => {
    history.push("/hisobot/restrTolov");
  };
  return (
    <div style={{ display: "flex" }} className="body_actions">
      <Button onClick={openAdd} type="primary" className="action_btn main-btn">
        <RiAddLine className="action_icon"></RiAddLine> &nbsp;{" "}
        <span className="action_text">Қўшиш</span>
      </Button>
      &nbsp; &nbsp;
      <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
        <RiPencilLine className="action_icon"></RiPencilLine> &nbsp;{" "}
        <span className="action_text">Ўзгартириш</span>
      </Button>
      &nbsp; &nbsp;
      <Popconfirm
        title="Маьлумот ўчирилади!"
        visible={visible}
        onConfirm={handleDelete}
        okButtonProps={{ loading: loading }}
        onCancel={() => setVisible(false)}
      >
        <Button
          icon
          loading={loading}
          className="action_btn delete-btn"
          onClick={() => {
            if (rowId) {
              setVisible(true);
            } else message.warning("Qatorni tanlang!");
          }}
        >
          <RiCloseLine className="action_icon"></RiCloseLine> &nbsp;{" "}
          <span className="action_text">Ўчириш</span>
        </Button>
      </Popconfirm>
      &nbsp; &nbsp;
      <Button onClick={toExcel} type="primary" className="action_btn main-btn">
        <RiFileExcel2Fill className="action_icon"></RiFileExcel2Fill> &nbsp;{" "}
        <span className="action_text">Excelга олиш</span>
      </Button>
      &nbsp; &nbsp;
      {location.pathname == "/sotuv-bolimi" && (
        <>
          <Button
            onClick={toPrint}
            type="primary"
            className="action_btn main-btn"
          >
            <RiPrinterFill className="action_icon"></RiPrinterFill>
          </Button>
          &nbsp; &nbsp;
          <Button
            onClick={toNaklad}
            type="primary"
            className="action_btn main-btn"
          >
            <RiHonourFill className="action_icon"></RiHonourFill>
          </Button>
        </>
      )}
      {location.pathname == "/mijoz-vozvrat" && (
        <Button
          onClick={() => toPrint("/print-vozvrat")}
          type="primary"
          className="action_btn main-btn"
        >
          <RiPrinterFill className="action_icon"></RiPrinterFill>
        </Button>
      )}
      {location.pathname == "/xarajat-tolov" && (
        <>
          <Button
            onClick={toKassaXisob}
            type="primary"
            className="action_btn main-btn"
          >
            <RiHonourFill className="action_icon"></RiHonourFill>
          </Button>
        </>
      )}
      {location.pathname == "/mijoz-tolov" && (
        <>
          <Button
            onClick={toRestrTolov}
            type="primary"
            className="action_btn main-btn"
          >
            <RiHonourFill className="action_icon"></RiHonourFill>
          </Button>
        </>
      )}
      &nbsp; &nbsp;
      {(location.pathname == "/" ||
        location.pathname == "/maxsulot-kirimi" ||
        location.pathname == "/sotuv-bolimi" ||
        location.pathname == "/mijoz-tolov" ||
        location.pathname == "/xarajat-tolov") && (
        <>
          <DatePicker
            value={moment(values["sana1"])}
            format="DD.MM.YYYY"
            allowClear={false}
            locale={locale}
            onChange={(v) => {
              const e = {
                target: {
                  name: "sana1",
                  value: moment(v).format("YYYY-MM-DD"),
                },
              };
              setValues({ [e.target.name]: e.target.value });
            }}
          />
          &nbsp; &nbsp;
          <DatePicker
            value={moment(values["sana2"])}
            format="DD.MM.YYYY"
            allowClear={false}
            locale={locale}
            onChange={(v) => {
              const e = {
                target: {
                  name: "sana2",
                  value: moment(v).format("YYYY-MM-DD"),
                },
              };
              setValues({ ...values, [e.target.name]: e.target.value });
            }}
          />
          &nbsp; &nbsp;
          <Button
            onClick={() => RefreshPage()}
            type="primary"
            className="action_btn main-btn"
          >
            <RiRefreshFill className="action_icon"></RiRefreshFill>
          </Button>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    data: state.app.mainTableData,
    loading: state.app.loading,
    values: state.app.values,
    allData: state.app.allData,
    currentPage: state.app.currentPage,
  };
};

export default connect(mapStateToProps, {
  setModal,
  getMainTableData,
  setValues,
  setRowId,
  setHisobot,
})(Actions);
