import {HiOutlinePhotograph} from "react-icons/hi";
import {BaseUrl} from "../../BaseUrl";
import {FOTOXISOBOT} from "../../Component/Sidebar/PageTypes";
import SanaFormat from "../../Functions/SanaFormat";
import Zoom from "react-medium-image-zoom";

const FotoHisobot = {
    title: "Фото хисобот",
    path: "/photoHisobot",
    type: FOTOXISOBOT,
    icon: <HiOutlinePhotograph/>,
    modalWidth: "1336px",
    filters: [
        {
            dataIndex: "mijozlar",
        },
        {
            dataIndex: "agentlar",
        },
    ],
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        // {
        //     title: <div className="table_head">ID</div>,
        //     dataIndex: "Id",
        //     width: "70px",
        //     align: "center",
        // },
        {
            title: <div className="table_head">Сана</div>,
            dataIndex: "time_create",
            key: "nomi",
            width: "160px",
            render: (text) => SanaFormat(text),
        },
        {
            title: <div className="table_head">Агент</div>,
            dataIndex: "agentlar",
            key: "nomi",
            onFilter: (value, record) => record.agentlar?.indexOf(value) === 0,
            width: "200px",
        },
        {
            title: <div className="table_head">Мижоз</div>,
            dataIndex: "mijozlar",
            key: "nomi",
            onFilter: (value, record) => record.mijozlar?.indexOf(value) === 0,
            width: "180px",
        },
        {
            title: <div className="table_head">Расми 1</div>,
            dataIndex: "img1",
            key: "img1",
            width: "70px",
            render: (text) => {
                if (text) {
                    return (
                        <Zoom zoomMargin={2}>
                            <picture>
                                <source
                                    media="max-width: 200px"
                                    srcSet={BaseUrl + text.slice(1)}
                                />
                                <img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
        {
            title: <div className="table_head">Расми 2</div>,
            dataIndex: "img2",
            key: "img2",
            width: "70px",
            render: (text) => {
                if (text) {
                    return (
                        <Zoom zoomMargin={2}>
                            <picture>
                                <source
                                    media="max-width: 200px"
                                    srcSet={BaseUrl + text.slice(1)}
                                />
                                <img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
        {
            title: <div className="table_head">Расми 3</div>,
            dataIndex: "img3",
            key: "img3",
            width: "70px",
            render: (text) => {
                if (text) {
                    return (
                        <Zoom zoomMargin={2}>
                            <picture>
                                <source
                                    media="max-width: 200px"
                                    srcSet={BaseUrl + text.slice(1)}
                                />
                                <img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
        {
            title: <div className="table_head">Изох</div>,
            dataIndex: "izoh",
            key: "nomi",
            width: "178px",
        },
    ],
    allData: [
        {value: "mijoz_id", prop: "mijozlar", get: "fio"},
        {value: "agent_id", prop: "agentlar", get: "fio"},
    ],
    forms: [],
};

export default FotoHisobot;
