import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import "./Sidebar.css";
import brand from "../../images/brand.png";
import {Header} from "antd/lib/layout/layout";
import SearchCom from "../Header/SearchCom";
import avatar from "../../images/avatar.svg";
import "../Header/Header.css";
import MainModal from "../Modals/MainModal";
import {Link, useHistory} from "react-router-dom";

import Modal2 from "../../Pages/ServicePages/Components/Modal";
import Page from "./Page";
import {
    deleteLinks,
    getAllData,
    getHisobot,
    getMainTableData,
    setCurrentPage,
    setRowId,
} from "../../Actions/AppActions";
import {connect, useDispatch, useSelector} from "react-redux";
import PagesClass from "../../Pages/PagesClass";
import ServicePages from "../../Pages/ServicePages";
import HisobotPages from "../../Pages/Hisobot";
import {useMediaQuery} from "react-responsive";
import {BaseUrl} from "../../BaseUrl";
import axios from "axios";
import {TIME} from "../../Actions/AppTypes";

const {Content, Sider} = Layout;
const {SubMenu} = Menu;

function SiderDemo({
                       pages,
                       setCurrentPage,
                       currentPage,
                       links,
                       deleteLinks,
                       setRowId,
                       data,
                       getMainTableData,
                       getAllData,
                       allData,
                       getHisobot,
                   }) {
    const [searchDataSource, setSearchDataSource] = useState([]);
    const [collapsed, setCollapsed] = useState(
        useMediaQuery({query: "(max-width: 500px)"})
    );
    const big = useMediaQuery({query: "(max-width: 500px)"});
    useEffect(() => {
        setCollapsed(big);
    }, [big]);
    const scrollContainer = document.querySelector(".link_bar");
    useEffect(() => {
        setTimeout(() => {
            const off = document.querySelector(".active_link")?.offsetLeft;
            const ff = off - 216;
            scrollContainer?.scroll({
                top: 0,
                left: ff,
                behavior: "smooth",
            });
        }, 100);
        if (
            currentPage.path &&
            currentPage.path != "/service" &&
            currentPage.path != "/hisobot"
        ) {
            if (currentPage.allData) {
                getAllData(currentPage.allData.map((al) => al.prop));
            }
            if (currentPage.path.includes("hisobot")) {
                // getHisobot(currentPage.path, {
                //   sana: moment().format("YYYY-MM-DD HH:mm:ss"),
                //   sana2: moment().format("YYYY-MM-DD HH:mm:ss"),
                // });
            } else {
                getMainTableData(currentPage.path);
            }
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage.allData?.length > 0) {
            setSearchDataSource(
                data.map((d) => {
                    let a = {};
                    currentPage.allData.forEach((obj) => {
                        if (allData[obj.prop]?.find((k) => k.Id == d[obj.value])) {
                            a[obj.prop] = allData[obj.prop]?.find(
                                (k) => k.Id == d[obj.value]
                            )[obj.get];
                        }
                    });

                    return {...d, ...a};
                })
            );
        } else {
            setSearchDataSource(data);
        }
    }, [data, allData]);
    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };
    const {location} = useHistory();

    scrollContainer?.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
    });

    const goBack = (path) => {
        const index = links.findIndex((d) => d.path == path);
        if (index > 0) {
            setCurrentPage(links[index - 1]);
        } else {
        }
    };

    //------------------------------------------------------------------\\
    useEffect(() => {
        if (location.pathname == "/") {
            window.location.href = "/maxsulot-kirimi";
        }
        let pagess = [];
        PagesClass.forEach((d) => {
            if (d.submenu) {
                pagess = [...pagess, ...d.submenu];
            } else {
                pagess = [...pagess, d];
            }
        });

        const pages = [...pagess, ...ServicePages(), ...HisobotPages()];
        setCurrentPage(pages.find((p) => p.path == location.pathname));

    }, []);
    const {app} = useSelector(s => s);
    const dispatch = useDispatch();
    useEffect(() => {
        if (document.location.pathname === "/agent-buyurtma") {
            let arr = [];
            app.lastSeen.forEach(item => arr.push(item.buyurtma_id));
            axios({url: BaseUrl + "/agent-buyurtma-korildi", method: "post", data: {allId: arr}});
            dispatch({type: TIME, payload: null});
            console.log("ishaldim ")
        }
    }, [currentPage]);
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sider
                width={"230"}
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
            >
                <div className="sidebar_header">
                    <img width="50%" src={brand} alt="brand"/>
                </div>
                <Menu
                    theme="dark"
                    style={{fontSize: "1.2em"}}
                    defaultSelectedKeys={[location.pathname]}
                    mode="inline"
                >
                    {pages.map((d) => {
                        let a = "";
                        if (d.submenu) {
                            a = (
                                <SubMenu key={d.key} icon={d.icon} title={d.title}>
                                    {d.submenu.map((d) => (
                                        <Menu.Item key={d.path} icon={d.icon}>
                                            <Link onClick={() => setCurrentPage(d)} to={d.path}>
                                                {d.title}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            );
                        } else {
                            a = (
                                <Menu.Item key={d.path} icon={d.icon}>
                                    <Link onClick={() => setCurrentPage(d)} to={d.path}>
                                        {d.title}
                                    </Link>
                                </Menu.Item>
                            );
                        }

                        return a;
                    })}
                </Menu>
            </Sider>

            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{padding: 0, background: "#f0f2f5"}}
                >
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <SearchCom
                            setS={(d) => {
                                setSearchDataSource(d);
                            }}
                        ></SearchCom>
                        <div
                            style={{display: "flex", paddingRight: "15px"}}
                            className="user_avatar"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    marginRight: "1rem",
                                }}
                            >
                                <h4>{JSON.parse(window.localStorage.getItem("user")).fio}</h4>
                                {/* <span>
                  {JSON.parse(window.localStorage.getItem("user")).telefon}
                </span> */}
                            </div>
                            <img
                                style={{display: "block"}}
                                src={avatar}
                                alt={"avatar"}
                            ></img>
                        </div>
                    </div>
                </Header>

                <Content style={{margin: "0 16px"}}>
                    <div className="link_bar">
                        {links.map((d) => (
                            <div
                                className={`link ${
                                    currentPage.path == d.path && "active_link"
                                }`}
                            >
                                <Link
                                    onClick={() => {
                                        setCurrentPage(d);
                                        setRowId(null);
                                    }}
                                    to={d.path}
                                >
                                    {d.title}
                                </Link>
                                <div
                                    className="cancel_link"
                                    onClick={() => {
                                        goBack(d.path);
                                        deleteLinks(d);
                                    }}
                                >
                                    &times;
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: "calc(100vh - 116px)"}}
                    >
                        <Page type={currentPage.type} dataSource={searchDataSource}></Page>
                    </div>
                    <MainModal></MainModal>
                    <Modal2></Modal2>
                </Content>
            </Layout>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        currentPage: state.app.currentPage,
        links: state.app.links,
        data: state.app.mainTableData,
        loading: state.app.loading,
        allData: state.app.allData,
    };
};
export default connect(mapStateToProps, {
    setCurrentPage,
    setRowId,
    deleteLinks,
    getMainTableData,
    getHisobot,
    getAllData,
})(SiderDemo);
