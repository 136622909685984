// export default x => {
//   const y = parseInt(x)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//   return y;
// };

import numeral from "numeral";

export default (number, type) => {

    if (type && type == 1) {
        let a = "";
        if (number?.toString().length > 4) {
            a = numeral(number).format("0,0.00").replaceAll(",", " ");
        } else {
            a = number;
            a = removeLastTwoZeros(number);
        }
        return a;
    } else {
        return numeral(number).format("0,0").replaceAll(",", " ");
    }

    function removeLastTwoZeros(number) {
        let numberStr = number + "";
        let lastTwoNumbers = numberStr?.substring(number?.length - 2);
        let thirdNumberFromEnd = numberStr?.substring(number?.length - 3, number?.length - 2);
        if (lastTwoNumbers === "00" && thirdNumberFromEnd === ".") {
            number = number?.substring(0, number?.length - 3);
        }
        return number;
    }

};
