import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import MainTable from "../../Component/Tables/MainTable";
import NumberFormat from "../../Functions/NumberFormat";

const PrintBeautyTable = ({ element, allData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(
      element.document.map((d) => {
        return {
          ...d,
          olchovlar: allData.olchov_turlari.find((b) => b.Id == d.olchov_id)
            ? allData.olchov_turlari.find((b) => b.Id == d.olchov_id).nomi
            : null,
        };
      })
    );
  }, [element]);

  const costumColumns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Маҳсулот</div>,
      dataIndex: "nomi",
      key: "nomi",
      width: "200px",
    },
    // {
    //   title: <div className="table_head">Ед.Изм</div>,
    //   dataIndex: "olchovlar",
    //   key: "nomi",
    //   width: "100px",
    // },
    {
      title: <div className="table_head">Вазни</div>,
      dataIndex: "vazni",
      key: "nomi",
      align: "left",
      width: "50px",
    },
    {
      title: <div className="table_head">Блок</div>,
      dataIndex: "miqdor_blok",
      key: "nomi",
      align: "right",
      width: "130px",
      render: (text) => NumberFormat(text, 1),
    },
    {
      title: <div className="table_head">Сони</div>,
      dataIndex: "miqdor_soni",
      key: "nomi",
      align: "right",
      width: "130px",
      render: (text) => NumberFormat(text),
    },

    {
      title: <div className="table_head">Нархи сўм</div>,
      dataIndex: "sotish_narxi",
      key: "nomi",
      align: "right",
      width: "170px",
      render: (text, record) =>
        record.valyuta_turi == 1 ? 0 : NumberFormat(text),
    },

    {
      title: <div className="table_head">Нархи вал</div>,
      dataIndex: "sotish_narxi",
      key: "nomi",
      align: "right",
      width: "120px",
      render: (text, record) =>
        record.valyuta_turi == 2 ? 0 : NumberFormat(text, 1),
    },

    {
      title: <div className="table_head">Cумма сўм</div>,
      dataIndex: "sotish_narxi",
      align: "right",
      key: "nomi",
      width: "170px",
      render: (text, record) => {
        if (record.valyuta_turi == 2) {
          return isNaN(text * record.miqdor_soni)
            ? 0
            : NumberFormat(text * record.miqdor_soni);
        } else return null;
      },
    },
    {
      title: <div className="table_head">Сумма вал</div>,
      dataIndex: "sotish_narxi",
      align: "right",
      key: "nomi",
      width: "120px",
      render: (text, record) => {
        if (record.valyuta_turi == 1) {
          return isNaN(text * record.miqdor_soni)
            ? 0
            : NumberFormat(text * record.miqdor_soni, 1);
        } else return null;
      },
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MainTable
        notPag={true}
        data={data}
        notX={true}
        summary={true}
        costumColumns={costumColumns}
      ></MainTable>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    mainTableData: state.app.mainTableData,
    allData: state.app.allData,
  };
};
export default connect(mapStateToProps)(PrintBeautyTable);
